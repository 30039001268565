import Vue from 'vue';
import 'view-design/dist/styles/iview.css';
import './index.less';
import MainSlider from './MainSlider';

Vue.config.productionTip = false

new Vue({
  render: h => h(MainSlider),
}).$mount('#mainSlider')
