<template>
  <Carousel v-model="value1" loop autoplay arrow="always" :autoplay-speed="3000">
    <CarouselItem>
      <a class="slider samural_spirits_slider" href="samural_spirits.html">
        <img :src="'./static/slider_samurai_spirits.jpg'" alt="samural spirits">
      </a>
    </CarouselItem>
    <CarouselItem>
      <a class="slider dos68_slider" href="dos68.html">
        <img :src="'./static/slider_dos68.jpg'" alt="dos68">
      </a>
    </CarouselItem>
    <CarouselItem>
      <a class="slider aurora_slider" href="aurora.html">
        <img :src="'./static/slider_aurora.jpg'" alt="aurora">
      </a>
    </CarouselItem>
  </Carousel>
</template>

<script>
import { Carousel, CarouselItem } from 'view-design';

export default {
  name: "Dialog",
  components: {
    Carousel,
    CarouselItem,
  },
  props: {
    width: {
      type: String,
      default: "90%",
    },
  },
  data() {
    return {
      value1: 0,
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    wrapperClick(e) {
      let elem = e.target;
      while (elem !== e.currentTarget) {
        if (
          elem.classList.contains("close") ||
          elem.classList.contains("cancel")
        ) {
          this.close();
          break;
        }
        elem = elem.parentNode;
      }
    },
  },
};
</script>

<style lang="less">
.slider {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
}
.samural_spirits_slider {
  background-image: url(../assets/index/slider_samurai_spirits_bg.jpg);
}
.dos68_slider {
  background-image: url(../assets/index/slider_dos68_bg.jpg);
}
.aurora_slider {
  background-image: url(../assets/index/slider_aurora_bg.jpg);
}
</style>
